import styled from 'styled-components'
import { Body1, Subtitle3 } from 'assets/common/typography'
import { colors } from 'assets/common/colors'
import { StyledCenteredContainer } from 'assets/common/mixin'

const { white, black, black111, mint } = colors

export const StyledPeopleWrapper = styled(StyledCenteredContainer)`
  padding-top: 18rem;
  padding-bottom: 1rem;

  @media (max-width: 900px) {
    padding-top: 13rem;
  }
`

export const StyledContentContainer = styled.div`
  display: flex;
  gap: 11rem;
  align-items: self-start;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 1rem;
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    gap: 5rem;
  }
`

export const StyledHeroTitle = styled.h2`
  width: 35%;

  @media (max-width: 900px) {
    width: 100%;
  }
`
export const StyledHeroText = styled.div`
  width: 50%;

  p {
    margin-top: 0.5rem;
  }

  @media (max-width: 900px) {
    width: 100%;
    margin-top: -1rem;
  }
`

export const StyledValuesContainer = styled(StyledCenteredContainer)`
  margin-bottom: 5rem;
`

export const StyledValuesDescriptionTitle = styled.h6`
  font-family: 'Inter', Arial, Sans-Serif;
  color: ${white};
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.0325rem;
`

export const StyledValuesDescription = styled.div`
  margin-top: 1rem;
`

export const StyledValuesDescriptionText = styled(Body1)`
  //color: #777;
`

export const StyledValuesTitle = styled.h2`
  width: 70%;

  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: -1rem;
  }
`

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 5rem;

  @media (max-width: 900px) {
    flex-flow: column;
    margin-top: 2rem;
  }
`

export const StyledValuesSubTitle = styled(Body1)`
  color: #555555;

  @media (max-width: 700px) {
    font-size: 0.9375rem;
  }
`

export const StyledTeamImageContainer = styled.div`
  overflow: hidden;
  margin: -2rem 0;
  img {
    width: 100%;
    height: auto;
    margin-bottom: -0.25rem;
  }
  @media (max-width: 900px) {
    img {
      width: 100%;
      height: auto;
    }
  }
`
export const StyledVacancyBackground = styled.div`
  background: ${white};
  color: ${black};
  padding: 3rem 0;
`

export const StyledVacancyContainer = styled(StyledCenteredContainer)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const StyledJob = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${mint};
  padding: 1.25rem 1.75rem;
  cursor: pointer;
  //max-height: 4.9375rem;
`
export const StyledMobileJob = styled(StyledJob)`
  padding: 1.25rem 1rem;
  //max-height: 5.5rem;
`
export const StyledJobDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledJobSubtitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  align-items: center;
  color: ${black111};
  opacity: 0.5;
  &:last-child {
    opacity: 1;
  }
`

export const StyledJobSubtitle = styled.p`
  opacity: 0.4;
  font-size: 0.9375rem;
`

export const StyledJobMobileContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
  }
`
export const StyledJobMobileSubtitleContainer = styled.div`
  display: flex;
`

export const StyledTeamContainer = styled.div`
  position: relative;
  background-color: ${white};
`

export const StyledVideoBackground = styled.video`
  width: 100%;
  height: 500px;
  object-fit: fill;

  @media (max-width: 900px) {
    height: 300px;
  }
`

export const StyledTextOverlay = styled(StyledCenteredContainer)`
  margin-top: -28rem;

  h2 {
    margin-bottom: 7rem;
  }

  @media (max-width: 900px) {
    margin-top: -18rem;
    h2 {
      margin-bottom: 3rem;
    }
  }
`

export const StyledTeamList = styled.ul`
  color: #000;
  display: flex;
  gap: 2rem;
  justify-content: baseline;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 0;
  padding-left: 0;
  margin-top: 2rem;

  @media (max-width: 900px) {
    align-items: unset;
  }
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

export const StyledEmployeeCard = styled.li`
  list-style: none;
  position: relative;
  display: inline-block;

  img {
    height: auto;
    max-width: 19.375rem;
    width: 100%;
  }

  @media (max-width: 900px) {
    flex: 1 0 45%;
  }
`

export const SecondImage = styled.img`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.2s ease-in-out;

  ${StyledEmployeeCard}:hover & {
    opacity: 1;
  }
`

export const StyledEmployeeName = styled(Subtitle3)`
  font-weight: 800;
  line-height: 130%;
  margin: 1rem 0 0 0;

  @media (max-width: 900px) {
    margin: 0;
  }
`

export const StyledEmployeeTitle = styled(Body1)`
  line-height: 175%;
  letter-spacing: -0.0225rem;
  margin: 0;
  color: #11111160;
`
