import { useContext, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Context } from 'store'
import { ContactForm, Footer, Navigation, Vacancy, WorkEntry } from 'components'

import {
  PageNotFound,
  Services,
  People,
  News,
  NewsDetails,
  // Legal,
  Homepage,
  About,
  Work,
  Contact
} from 'pages'
import { getAllContent } from 'api/fetchData'
import { changeLoadingState, setData, setGeoData } from 'store/actions'
import { StyledApp, StyledLoaderContainer } from 'App.styled'
import { AnimatePresence } from 'framer-motion'
import fetchGeoData from './api/fetchGeoData'
import Loader from './components/Loader/Loader'

const App = () => {
  const {
    dispatch,
    state: { isLoading, showForm }
  } = useContext(Context)
  const location = useLocation()

  useEffect(() => {
    const fetchContent = async () => {
      // const data = await fetchData()
      const data = await getAllContent()
      if (!data) return

      dispatch(setData(data))
      dispatch(changeLoadingState(false))
      fetchGeoData().then((result) => {
        if (result.status === 200) {
          dispatch(setGeoData(result.data))
        }
      })
    }

    fetchContent()
  }, [dispatch])

  return (
    <AnimatePresence>
      {isLoading ? (
        <StyledLoaderContainer key="preloader">
          <Loader isInverted />
        </StyledLoaderContainer>
      ) : (
        <StyledApp key="application">
          <Navigation />
          {showForm && <ContactForm />}
          <TransitionGroup>
            <CSSTransition
              key={location.pathname}
              timeout={350}
              classNames="fade"
            >
              <Routes>
                <Route path="" element={<Homepage />} />
                <Route path="sobre" element={<About />} />
                <Route path="work" element={<Work />} />
                <Route path="work/:client/:title" element={<WorkEntry />} />
                <Route path="servicos" element={<Services />} />
                <Route path="pessoas" element={<People />} />
                <Route
                  path="pessoas/vagas/:id/:function/:location"
                  element={<Vacancy />}
                />
                <Route path="noticias" element={<News />} />
                <Route path="noticias/:key" element={<NewsDetails />} />
                <Route path="contato" element={<Contact />} />
                {/* <Route path="legal" element={<Legal />} /> */}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
          <Footer />
        </StyledApp>
      )}
    </AnimatePresence>
  )
}

export default App
