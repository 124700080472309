import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { ReactComponent as CheilLogodarkBg } from 'assets/images/cheil-logo-on-dark.svg'

import { MAIN_MENU } from 'assets/common/constants'
import { colors } from 'assets/common/colors'
import { Menu, Button } from 'components'
// import { Context } from 'store'
// import { showContactForm } from 'store/actions'
import {
  StyledNavigationWrapper,
  StyledNavigation,
  StyledLogoContainer,
  StyledNavigationItemsContainer,
  StyledNavigationItem,
  StyledContactButtonContainer,
  StyledContactButton,
  StyledMenuLine,
  StyledBurgerContainer,
  StyledBurger
} from './Navigation.styled'

const { mint, yellow, magenta, green } = colors

const menuColors = [mint, yellow, magenta, green]

const Burger = ({ open, setOpen }) => {
  return (
    <>
      <StyledBurgerContainer
        whileHover={{
          rotate: open ? 90 : 0
        }}
        style={{ position: open ? 'fixed' : 'inherit' }}
      >
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
          <StyledMenuLine />
          <StyledMenuLine />
          <StyledMenuLine shorter />
        </StyledBurger>
      </StyledBurgerContainer>
      {/* This is a placeholder so the layout doesn't shift and the real button can follow the menu scrolling as a position fixed component */}
      {open && (
        <StyledBurgerContainer style={{ zIndex: -10, pointerEvents: 'none' }}>
          <StyledBurger open={false}>
            <StyledMenuLine />
            <StyledMenuLine />
            <StyledMenuLine shorter />
          </StyledBurger>
        </StyledBurgerContainer>
      )}
    </>
  )
}

const Navigation = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)
  const node = React.useRef()
  // const { dispatch } = useContext(Context)

  return (
    <StyledNavigationWrapper>
      <StyledNavigation>
        <StyledLogoContainer id="cheil-logo" open={open}>
          <CheilLogodarkBg onClick={() => navigate('/')} />
        </StyledLogoContainer>
        <StyledNavigationItemsContainer>
          {MAIN_MENU.map((item, index) => (
            <StyledNavigationItem
              key={item}
              onClick={() =>
                item === 'serviços'
                  ? navigate('/servicos')
                  : navigate(`/${item}`)
              }
              iscurrent={pathname === `/${item}` ? 'true' : undefined}
              content={item}
              color={menuColors[index]}
            >
              {item}
              <span color={menuColors[index]}>{item}</span>
            </StyledNavigationItem>
          ))}
        </StyledNavigationItemsContainer>
        <StyledContactButtonContainer>
          <StyledContactButton>
            <Button
              handleClick={() =>
                window.open(
                  'mailto:novosnegocios@cheil.com',
                  '_blank',
                  'noopener'
                )
              }
              // handleClick={() => dispatch(showContactForm(true))}
              textButton="Entre em contato"
            />
          </StyledContactButton>
          <StyledBurgerContainer ref={node}>
            <Burger open={open} setOpen={setOpen} />
            <Menu open={open} setOpen={setOpen} />
          </StyledBurgerContainer>
        </StyledContactButtonContainer>
      </StyledNavigation>
    </StyledNavigationWrapper>
  )
}

Burger.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default Navigation
