import React, { useContext } from 'react'
// import { useNavigate } from 'react-router-dom'
import { getImageUrl } from 'sanityClient.js'
import { Context } from 'store'
import { Button, ContactPersonBlock } from 'components'
// import { showContactForm } from 'store/actions'
import { PortableText } from '@portabletext/react'
import { Body4 } from 'assets/common/typography'
import { ReactComponent as CheilLogodarkBg } from 'assets/images/cheil-logo-on-dark.svg'
import { ReactComponent as PatternStripe } from 'assets/images/pattern_stripe.svg'
import {
  DynamicSVG,
  StyledFooterBlock,
  StyledFooterContainer,
  StyledFooterWrapper,
  StyledTitle,
  StyledDescription,
  StyledTitleContainer,
  StyledPeopleContainer,
  StyledPeople,
  StyledContactDetailsContainer,
  StyledContactDetails,
  StyledAddressesContainer,
  StyledAddressTitle,
  StyledAddressPhoneNumber,
  StyledAddresses,
  StyledFooter,
  StyleFooterContent,
  StyledSocials,
  StyledLine
} from './Footer.styled'

const Footer = () => {
  // const navigate = useNavigate()
  const {
    // dispatch,
    state: { footer }
  } = useContext(Context)

  const { title, addresses, contacts, description, email, links, phone } =
    footer

  return (
    <StyledFooterBlock>
      <DynamicSVG viewBox="0 0 100 0.2">
        <PatternStripe />
      </DynamicSVG>
      <StyledFooterWrapper>
        <StyledFooterContainer>
          <StyledTitleContainer>
            <StyledTitle>{title}</StyledTitle>
            <PortableText
              value={description}
              components={{ block: { normal: StyledDescription } }}
            />
            <Button
              handleClick={() =>
                window.open(
                  'mailto:novosnegocios@cheil.com',
                  '_blank',
                  'noopener'
                )
              }
              // handleClick={() => dispatch(showContactForm(true))}
              textButton="Enviar mensagem"
              isNoArrow
              isGradient
            />
          </StyledTitleContainer>

          {contacts && contacts.length > 0 && (
            <StyledPeopleContainer>
              <StyledPeople>
                {contacts &&
                  contacts?.map((contact) => (
                    <ContactPersonBlock
                      data={contact}
                      key={contact._key}
                      isFooter
                    />
                  ))}
              </StyledPeople>
            </StyledPeopleContainer>
          )}

          <StyledContactDetailsContainer>
            <StyledContactDetails>{phone}</StyledContactDetails>
            <StyledContactDetails
              className="contact"
              onClick={() =>
                window.open(`mailto:${email}`, '_blank', 'noopener')
              }
              // onClick={() => dispatch(showContactForm(true))}
            >
              {email}
            </StyledContactDetails>
          </StyledContactDetailsContainer>

          <StyledAddressesContainer>
            {addresses &&
              addresses.map((item) => (
                <StyledAddresses key={item._key}>
                  <StyledAddressTitle>{item.title}</StyledAddressTitle>
                  <StyledAddressPhoneNumber>
                    {item.phone}
                  </StyledAddressPhoneNumber>
                  <PortableText value={item.info} />
                </StyledAddresses>
              ))}
          </StyledAddressesContainer>
        </StyledFooterContainer>

        <StyledFooter>
          <CheilLogodarkBg />
          <StyleFooterContent>
            {/* <Body4 onClick={() => navigate('/privacy')}>Privacy</Body4> */}
            {/* <Body4>Cookies</Body4> */}
            {/* <Body4 onClick={() => navigate('/legal')}>Terms & Conditions</Body4> */}
            <Body4 className="cheil">© {new Date().getFullYear()} Cheil</Body4>
            <StyledSocials>
              {links &&
                links.map((i) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                  <div key={i.url} onClick={() => window.open(i.url)}>
                    <img
                      src={getImageUrl(i.icon.asset._ref).url()}
                      alt={i.title}
                    />
                  </div>
                ))}
            </StyledSocials>
          </StyleFooterContent>
        </StyledFooter>
      </StyledFooterWrapper>
      <StyledLine />
    </StyledFooterBlock>
  )
}

export default Footer
