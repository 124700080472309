import { createClient } from '@sanity/client'

const client = createClient({
  projectId: 'z0qtt0tg',
  dataset: 'production',
  useCdn: false, // set to `false` to bypass the edge cache
  apiVersion: '2024-01-01' // use current date (YYYY-MM-DD) to target the latest API version
})

export async function getAllContent() {
  const content = await client.fetch('*')
  return content
}
