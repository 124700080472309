import styled, { css, keyframes } from 'styled-components'
import { colors } from 'assets/common/colors'
import { StyledCenteredContainer } from 'assets/common/mixin'
import { motion } from 'framer-motion'
import placeholderImage from '../../assets/images/placeholder-video.jpeg'

const { white, black, black111 } = colors

const rotation = keyframes` 
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`
export const StyledHeroBackground = styled(motion.div)`
  perspective: 600px;
  position: relative;
  background: ${white};
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
  }
`

export const StyledEmojiWrapper = styled.div`
  display: flex;

  &.emojiHands {
    margin-top: 0.5rem;
  }

  @media (min-width: 375px) and (max-width: 500px) {
    &.emojiHands {
      margin-top: 1rem;
    }
  }

  @media (min-width: 501px) and(max-width: 900px) {
    &.emojiHands {
      margin-top: 0;
    }
  }
`

export const StyledEmojiContainer = styled.div`
  margin: -3rem -2rem;
  height: 200px;
  width: 200px;

  &.emojiHands {
    margin: -3rem 0;
  }

  @media (min-width: 375px) and (max-width: 500px) {
    margin: -1rem;
    height: 80px;
    width: 80px;

    &.emojiHands {
      margin: -1rem 0;
    }
  }

  @media (min-width: 501px) and (max-width: 900px) {
    height: 100px;
    width: 100px;
    margin: -2rem -1rem;

    &.emojiHands {
      margin: -2rem 0;
    }
  }
`

export const StyledSpan = styled.span`
  letter-spacing: 0.3rem;

  @media (max-width: 900px) {
    letter-spacing: 0.2rem;
  }
`

export const StyledVideoModal = styled.div`
  // very wonky selector for this specific div inside the video modal that needs a different padding, could mess other things up so take note
  div > div > div > div {
    padding: 45% 0 0;
  }

  // For the video close button
  button {
    z-index: 5;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    top: 0.5rem;
    right: 0.5rem;
  }

  video {
    z-index: 10000;
  }
`
export const StyledHeroContainer = styled(StyledCenteredContainer)`
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: flex-end;

  @media (min-width: 2100px) {
    min-height: 60vh;
  }
`

export const StyledHeroVideo = styled.video`
  background-image: url(${placeholderImage});
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: 35%;
  background-size: cover;
  background-color: rgb(17, 17, 17);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  object-fit: cover;
  ${({ isBlurred }) => {
    if (isBlurred) {
      return css`
        transform: scale(1.1);
        filter: blur(2rem) brightness(30%);
      `
    }
    return ''
  }}
`

export const StyledHeroTitle = styled.p`
  font-family: 'Montserrat', Arial, Sans-Serif;
  font-size: 1.725rem; // 28px
  font-weight: 800;
  line-height: 120%;
  letter-spacing: -0.06375rem;
  margin-bottom: 1rem;
  z-index: 2;

  @media (max-width: 900px) {
    font-size: 1.50494rem;
    font-weight: 700;
    line-height: 130%; /* 1.95644rem */
    letter-spacing: -0.04513rem;
  }
`

export const StyledPlayReelCircle = styled(motion.div)`
  position: absolute;
  top: -66px;
  left: -66px;
  z-index: 9;

  svg {
    animation: ${rotation} 10s infinite linear;
    cursor: pointer;
  }

  @media (max-width: 575px) {
    top: -3.53125rem;
    left: -3.53125rem;

    svg {
      width: 7.0625rem;
      height: 7.0625rem;
    }
  }
`

export const StyledSubHeroBackground = styled.div`
  background: ${white};
  color: ${black};
`

export const StyledSubHeroContainer = styled(StyledCenteredContainer)`
  display: flex;
  flex-wrap: wrap;
  gap: 9.375rem;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 0;
  }
`

export const StyledSunIcon = styled.div`
  display: inline-block;
  vertical-align: middle;

  //@media (prefers-color-scheme: dark) {
  //  color: black;
  //}

  height: 10rem;
  width: 10rem;
  margin: 0px -3.3rem 0px -3.2rem;

  //@media (prefers-color-scheme: dark) {
  //  filter: invert(1);
  //}

  @media (max-width: 300px) {
    height: 3.5rem;
    width: 3.5rem;
    margin: 0 -1.4rem 0 -1.3rem;
  }

  @media (min-width: 300px) and (max-width: 375px) {
    height: 4rem;
    width: 4rem;
    margin: 0 -1.65rem 0 -1.55rem;
  }

  @media (min-width: 375px) and (max-width: 900px) {
    height: 5rem;
    width: 5rem;
    margin: 0 -2rem 0 -1.9rem;
  }

  @media (min-width: 1430px) {
    margin: 0px -3.9rem 0px -3.6rem;
  }
`

export const StyledHeartIcon = styled.div`
  display: inline-block;
  vertical-align: middle;
  height: 11rem;
  width: 11rem;
  margin: -0.8rem 0 0 0.5rem;

  @media (max-width: 300px) {
    height: 4rem;
    width: 4rem;
  }

  @media (min-width: 300px) and (max-width: 375px) {
    height: 5rem;
    width: 5rem;
  }

  @media (min-width: 375px) and (max-width: 900px) {
    height: 6rem;
    width: 6rem;
  }
`
export const StyledSubHeroTitle = styled.p`
  font-family: 'Montserrat', Arial, Sans-Serif;
  font-size: 6.4rem; // 102.4px
  font-weight: 900;
  line-height: 30%;
  letter-spacing: -0.03rem;
  margin-bottom: 1rem;
  margin-top: 0;
  width: 55%;

  @media (max-width: 300px) {
    margin-bottom: 2rem;
    font-size: 2.4rem;
    line-height: 50%;
  }

  @media (min-width: 300px) and (max-width: 375px) {
    margin-bottom: 3rem;
    font-size: 2.8rem;
  }

  @media (min-width: 375px) and (max-width: 900px) {
    margin-bottom: 3rem;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 1429px) {
    font-size: 5rem;
  }
`

export const StyledSubHeroDescriptionContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
  margin-top: 9rem;

  @media (max-width: 1200px) {
    margin-top: -2rem;
  }
`

export const StyledSubHeroDescription = styled.p`
  /* width: 95%; */
  font-family: 'Inter', Arial, Sans-Serif;
  font-size: 1.625rem; // 26px
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.0325rem;
  margin-bottom: 1rem;

  @media (max-width: 900px) {
    font-size: 1.125rem;
    letter-spacing: -0.0225rem;
    margin-bottom: 0;
  }

  @media (max-width: 1200px) {
    padding-top: 5%;
  }
`
export const StyledLatestWorkBackground = styled.div`
  // for whatever weird reason the divs are not connecting otherwise
  margin-top: -1px;
  background: linear-gradient(${white} 50%, ${black111} 50%);
  color: ${black};
`

export const StyledLatestWorkContainer = styled(StyledCenteredContainer)`
  display: flex;
  flex-direction: column;
`

export const StyledPortfolioTitle = styled.p`
  font-family: 'Montserrat', Arial, Sans-Serif;
  font-size: 2.125rem; // 34px
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: -0.06375rem;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;

  @media (max-width: 900px) {
    font-size: 1.41669rem;
    letter-spacing: -0.0425rem;
  }
`

export const StyledLatestWork = styled.div`
  display: flex;
  position: relative;
  min-height: 50rem;

  @media (max-width: 1500px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: auto;
    }
  }
`

export const StyledWorkImageContainer = styled.div`
  cursor: pointer;

  @media (max-width: 900px) {
    align-self: flex-start;
    img {
      width: 100%;
      height: auto;
    }
  }

  ${({ position }) => {
    if (position === 0) {
      return css`
        align-self: flex-start;
      `
    }

    if (position === 1) {
      return css`
        align-self: center;
      `
    }

    if (position === 2) {
      return css`
        align-self: flex-end;
      `
    }
    return null
  }}
`

export const StyledLatestWorkButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;

  @media (max-width: 900px) {
    justify-content: center;
  }
`

export const StyledTeamBackground = styled.div`
  margin-top: 8rem;
  background: ${white};
  color: ${black};

  @media (max-width: 1200px) {
    margin-top: 2rem;
  }
`

export const StyledTeamContainer = styled(StyledCenteredContainer)`
  min-height: 48.75rem;

  @media (min-width: 1200px) {
    display: flex;
    align-items: center;
    gap: 5rem;
  }

  @media (max-width: 500px) {
    min-height: 25rem;
  }
`

export const StyledTeamContent = styled.div`
  @media (min-width: 1200px) {
    flex: 1;
    order: 1;
  }
`

export const StyledTeamPhotosContainer = styled.div`
  position: relative;
  max-width: 40rem;
  aspect-ratio: 0.95;
  order: 2;
  flex-basis: 50%;
  margin-top: -15rem;

  @media (max-width: 1200px) {
    margin-bottom: 5rem;
    margin-top: -8rem;
  }
`

export const StyledTeamPhoto = styled(motion.img)`
  position: absolute;
  //transition: transform 0.9s ease-in-out;
  object-fit: cover;
  aspect-ratio: 1;

  ${({ position }) => {
    if (position === 0) {
      return css`
        /* background: salmon; */
        right: 5%;
        top: 0;
        width: 50%;
      `
    }

    if (position === 1) {
      return css`
        top: 33%;
        left: 0;
        width: 40%;
      `
    }

    if (position === 2) {
      return css`
        bottom: 0;
        left: 15%;
        width: 35%;
      `
    }

    if (position === 3) {
      return css`
        bottom: 7%;
        right: 0;
        width: 40%;
      `
    }
    return css`
      top: 12.5rem;
      left: 31.25rem;
      @media (max-width: 900px) {
        top: -4.625rem;
        left: -1.125rem;
      }
    `
  }};
`
export const StyledAboutBackground = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 8rem 0;

  @media (min-width: 1000px) {
    padding: 0;
    height: 544px;
  }
`

export const StyledVideoBackground = styled.video`
  transform: scaleX(1.2) scaleY(1.5);
  width: 100%;
  height: 100%;
  min-height: 544px;
  object-fit: cover;
  overflow: hidden;
  margin: -8rem 0;
`

export const StyledAboutContainer = styled(StyledCenteredContainer)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

export const StyledTextOverlay = styled.div`
  position: relative;
  height: fit-content;
  color: ${white};
  width: 73%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    width: 100%;
  }

  @media (min-width: 2000px) {
    width: 80%;
  }
`

export const StyledAboutDescription = styled.p`
  font-family: 'Inter', Arial, Sans-Serif;
  font-size: 1.625rem; // 26px
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.0325rem;
  margin: 0;
  margin-bottom: 1rem;

  @media (max-width: 900px) {
    font-size: 1.125rem;
    letter-spacing: -0.0225rem;
  }
`
