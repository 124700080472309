import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'components'
import { SubTitle1 } from 'assets/common/typography'
import {
  StyledPageNotFoundWrapper,
  StyledPageNotFoundContainer
} from './PageNotFound.styled'

const PageNotFound = () => {
  const navigate = useNavigate()

  return (
    <StyledPageNotFoundWrapper>
      <StyledPageNotFoundContainer>
        <h1>Não há nada por aqui... 😵️</h1>
        <SubTitle1>
          A página que você estava procurando não pôde ser encontrada. Ela pode
          ter sido removida ou você pode ter digitado alguma palavra errada
          enquanto procurava por ela.
        </SubTitle1>
      </StyledPageNotFoundContainer>
      <Button
        isGradient
        handleClick={() => navigate('/')}
        textButton="Voltar para a Home"
      />
    </StyledPageNotFoundWrapper>
  )
}

export default PageNotFound
