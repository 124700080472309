import React, { useContext } from 'react'
import { PropTypes } from 'prop-types'
import { Context } from 'store'
import { setContactPerson } from 'store/actions'
import { ImageBlock, ContactPersonBlock } from 'components'
import { ReactComponent as EnvelopIcon } from 'assets/images/envelop.svg'
import { useWindowSize } from '../../hooks'
import {
  StyledContactBlock,
  StyledDescriptionContainer,
  StyledDescription,
  StyledContactDetails,
  StyledContactImage,
  StyledTextEmojiContainer,
  StyledContactNote,
  StyledContactText
} from './ContactBlock.styled'

const ContactBlock = ({
  details: { contact_description, contact_details }
}) => {
  const { width } = useWindowSize()
  const { dispatch } = useContext(Context)

  if (!contact_details || !contact_description) return null

  const {
    contact_name,
    title: contactTitle,
    icon,
    contact_background_color
  } = contact_details

  const handleClick = () => {
    window.open('mailto:novosnegocios@cheil.com', '_blank', 'noopener')
    // dispatch(showContactForm(true))
    dispatch(setContactPerson(contact_details))
  }

  return contact_details && width > 1000 ? (
    <StyledContactBlock>
      <StyledDescriptionContainer>
        <StyledDescription>{contact_description}</StyledDescription>
      </StyledDescriptionContainer>
      <StyledContactDetails
        bgcolor={
          (contact_background_color && contact_background_color.hex) || '#555'
        }
      >
        <StyledContactImage>
          <ImageBlock value={icon} name={contact_name} />
        </StyledContactImage>
        <StyledContactText>
          <h6>{contact_name}</h6>
          <h6 className="italic">{contactTitle}</h6>
          <StyledTextEmojiContainer onClick={handleClick}>
            <StyledContactNote>
              <EnvelopIcon /> Enviar mensagem
            </StyledContactNote>
          </StyledTextEmojiContainer>
        </StyledContactText>
      </StyledContactDetails>
    </StyledContactBlock>
  ) : (
    <ContactPersonBlock data={contact_details} isWorkEntry />
  )
}

ContactBlock.propTypes = {
  details: PropTypes.shape({
    contact_description: PropTypes.string,
    contact_details: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
}

export default ContactBlock
