import React, { useContext, useState } from 'react'
import { Button } from 'components'

// import sendEmail from 'api/sendEmail'
import emailjs from '@emailjs/browser'

import { Context } from 'store'
import { showContactForm, setContactPerson } from 'store/actions'
import { Body1 } from 'assets/common/typography'
import { ReactComponent as CloseIcon } from 'assets/images/close_icon.svg'
import {
  StyledContactFormContainer,
  StyledContactFormWrapper,
  StyledFormFields,
  StyledRainbowOutline,
  StyledCloseBtn,
  StyledConfirmPopupTitle,
  StyledLoaderContainer,
  StyledDisabledButtonWrapper
} from './ContactForm.styled'
import Loader from '../Loader/Loader'

const ContactForm = () => {
  const {
    dispatch,
    state: { contactPerson }
  } = useContext(Context)

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [confirmationOpen, setConfirmationOpen] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const defaultEmail = 'novosnegocios@cheil.com'
    const emailTo = contactPerson.contact_email || defaultEmail
    setIsLoading(true)

    try {
      const response = await emailjs.send(
        'service_zkbmxrf',
        'template_duv4pwe',
        {
          emailTo,
          name,
          email,
          subject,
          message,
          contact_name: contactPerson.name || 'Novos Negócios Cheil Brasil'
        },
        {
          publicKey: 'DlJvF3TzEZwtlb8n1'
        }
      )
      console.log(response)
    } catch (error) {
      console.error('Error sending email', error)
    } finally {
      setIsLoading(false)
      setConfirmationOpen(true)
    }

    // await sendEmail({
    //   emailTo,
    //   name,
    //   email,
    //   subject,
    //   message,
    //   contact_name: contactPerson.name || 'Contato'
    // })
  }

  const handleModalClose = () => {
    dispatch(showContactForm(false))
    setConfirmationOpen(false)
    dispatch(
      setContactPerson({
        name: '',
        email: 'novosnegocios@cheil.com'
      })
    )
  }

  return (
    <StyledContactFormWrapper>
      <StyledContactFormContainer>
        <StyledCloseBtn onClick={handleModalClose}>
          <CloseIcon />
        </StyledCloseBtn>
        {!confirmationOpen ? (
          <>
            <h4>
              {contactPerson.contact_name
                ? `Escreva para ${contactPerson.contact_name}`
                : 'Como podemos ajudar você?'}
            </h4>
            {!isLoading && (
              <Body1>
                {contactPerson.contact_email
                  ? `Tem alguma pergunta ou quer discutir um projeto juntos? Adoraríamos ouvir de você. Se preferir nos enviar um e-mail, entre em contato com ${contactPerson.contact_email} ou novosnegocios@cheil.com`
                  : `Pode ser sobre um projeto novo, evento, parceria ou algo da imprensa. Queremos saber mais sobre os desafios da sua marca.`}
              </Body1>
            )}
            <StyledFormFields onSubmit={handleSubmit}>
              {isLoading ? (
                <StyledLoaderContainer>
                  <Loader />
                </StyledLoaderContainer>
              ) : (
                <>
                  <label htmlFor="name">
                    Qual é o seu nome?
                    <StyledRainbowOutline>
                      <input
                        onChange={(e) => setName(e.target.value)}
                        required
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Seu nome"
                        value={name}
                      />
                    </StyledRainbowOutline>
                  </label>

                  <label htmlFor="email">
                    E qual é o seu endereço comercial de e-mail?
                    <StyledRainbowOutline>
                      <input
                        type="email"
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        placeholder="Seu e-mail comercial"
                        required
                        size="30"
                        value={email}
                      />
                    </StyledRainbowOutline>
                  </label>

                  <label htmlFor="subject">
                    É sobre o quê?
                    <StyledRainbowOutline>
                      <input
                        value={subject}
                        type="text"
                        name="subject"
                        onChange={(e) => setSubject(e.target.value)}
                        required
                        placeholder="Assunto"
                      />
                    </StyledRainbowOutline>
                  </label>

                  <label htmlFor="message">
                    Qual é a sua mensagem?
                    <StyledRainbowOutline>
                      <textarea
                        value={message}
                        minLength={30}
                        name="message"
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        placeholder="Conte-nos como podemos ajudá-lo..."
                      />
                    </StyledRainbowOutline>
                  </label>
                </>
              )}
              {isLoading ? (
                <StyledDisabledButtonWrapper>
                  <Button
                    whiteBg
                    isGradient
                    isDisabled={isLoading}
                    textButton="Enviando..."
                    isNoArrow
                  />
                </StyledDisabledButtonWrapper>
              ) : (
                <Button
                  whiteBg
                  type="submit"
                  isGradient
                  textButton="Enviar mensagem"
                />
              )}
            </StyledFormFields>
          </>
        ) : (
          <>
            <StyledConfirmPopupTitle>
              Obrigado por entrar em contato.
            </StyledConfirmPopupTitle>
            <Body1>
              Sua mensagem foi enviada com sucesso! Agradecemos por entrar em
              contato.
            </Body1>
            {/* <Body1>
              Sua mensagem foi enviada com sucesso! Uma cópia foi enviada para
              sua caixa de correio. Agradecemos por entrar em contato.
            </Body1> */}
          </>
        )}
      </StyledContactFormContainer>
    </StyledContactFormWrapper>
  )
}

export default ContactForm
