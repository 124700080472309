/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from 'react'

import { ReactComponent as CloseIcon } from 'assets/images/close_icon.svg'
import AlertImage from 'assets/images/alerta_de_golpe.jpg'
import {
  StyledModalWrapper,
  StyledModalContainer,
  StyledCloseBtn
} from './AlertModal.styled'

const AlertModal = () => {
  const [showModal, setShowModal] = useState(false)
  const handleModalClose = () => setShowModal(false)

  useEffect(() => {
    setShowModal(true)
    return () => setShowModal(false)
  }, [])

  return (
    showModal && (
      <StyledModalWrapper>
        <StyledModalContainer>
          <StyledCloseBtn onClick={handleModalClose}>
            <CloseIcon />
          </StyledCloseBtn>
          <img src={AlertImage} alt="alert" />
        </StyledModalContainer>
      </StyledModalWrapper>
    )
  )
}

export default AlertModal
