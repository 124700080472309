import React, { useContext } from 'react'
import { PortableText } from '@portabletext/react'
import { getImageUrl } from 'sanityClient.js'

import { Context } from 'store'
import { Button, HelmetSEO } from 'components'
// import { showContactForm } from 'store/actions'

import { SubTitle2 } from 'assets/common/typography'

import {
  StyledContactWrapper,
  StyledContactContainer,
  StyledContactDescription,
  StyledLocationsContainer,
  StyledLocationsList,
  StyledLocation,
  StyledLocationContent
} from './Contact.styled'

const helmetCopy = {
  title: 'Informações de Contato | Cheil Brasil',
  canonical: '/contato',
  description: '',
  keywords: ''
}

const Contact = () => {
  const {
    // dispatch,
    state: { footer }
  } = useContext(Context)

  return (
    <StyledContactWrapper>
      <StyledContactContainer>
        <HelmetSEO copy={helmetCopy} />
        <h2>{footer.phone}</h2>
        <div
          className="email"
          aria-hidden="true"
          onClick={() =>
            window.open(`mailto:${footer.email}`, '_blank', 'noopener')
          }
          // onClick={() => dispatch(showContactForm(true))}
        >
          <h3>{footer.email}</h3>
        </div>
        <PortableText
          value={footer.contact_description}
          components={{
            block: {
              normal: StyledContactDescription
            }
          }}
        />
        <Button
          handleClick={() =>
            window.open(`mailto:${footer.email}`, '_blank', 'noopener')
          }
          // handleClick={() => dispatch(showContactForm(true))}
          textButton="Enviar mensagem"
          isNoArrow
          isGradient
        />
      </StyledContactContainer>

      <StyledLocationsContainer>
        <h4>Localização</h4>
        <StyledLocationsList>
          {footer.addresses.map((loc) => (
            <StyledLocation key={loc._key}>
              {loc.office_photo && (
                <img
                  src={getImageUrl(loc.office_photo).url()}
                  alt={loc.title}
                />
              )}
              <SubTitle2>{loc.title}</SubTitle2>
              <PortableText
                value={loc.info}
                components={{
                  block: {
                    normal: StyledLocationContent
                  }
                }}
              />
              <p>{loc.phone}</p>
            </StyledLocation>
          ))}
        </StyledLocationsList>
      </StyledLocationsContainer>
    </StyledContactWrapper>
  )
}

export default Contact
