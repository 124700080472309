import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { getImageUrl } from 'sanityClient.js'
import { Body2 } from 'assets/common/typography'
import { generatePath } from 'assets/common/helper'
import {
  StyledImagesWrapper,
  StyledText,
  StyledImage,
  StyledTitle,
  MinHeightStyledImage
} from './ImageWithTextOverlay.styled'

const ImageWithTextOverlay = ({
  item: { _id, client, title, case_thumbnail, hero_image, thumbnail_title },
  isHeroImage,
  isOnHomepage
}) => {
  const navigate = useNavigate()

  let imageElement

  if (isHeroImage && hero_image) {
    imageElement = (
      <StyledImage src={getImageUrl(hero_image).url()} alt="case hero" />
    )
  } else if (case_thumbnail) {
    imageElement = (
      <MinHeightStyledImage
        src={getImageUrl(case_thumbnail).width(640).height(640).url()}
        alt="case thumbnail"
        isOnHomepage={isOnHomepage}
      />
    )
  } else if (isHeroImage && !hero_image) {
    imageElement = (
      <StyledImage
        src="https://cdn.sanity.io/images/8teahrr3/production/07efe7c4452782731418ed22e50f54218f246f53-1300x700.jpg"
        alt="no image"
      />
    )
  } else {
    imageElement = (
      <StyledImage
        src="https://cdn.sanity.io/images/8teahrr3/production/67bb8661483086ba4301278415026342b9464f34-640x640.jpg"
        alt="no image"
      />
    )
  }

  const handleClick = () => {
    navigate(`/work/${generatePath(client, title)}`)
  }

  return (
    <StyledImagesWrapper
      key={_id}
      onClick={handleClick}
      isOnHomepage={isOnHomepage}
    >
      {imageElement}
      <StyledText>
        <Body2>{client}</Body2>
        <StyledTitle>
          {case_thumbnail && thumbnail_title ? thumbnail_title : title}
        </StyledTitle>
      </StyledText>
    </StyledImagesWrapper>
  )
}

ImageWithTextOverlay.propTypes = {
  item: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    client: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.arrayOf(
      PropTypes.shape({
        _key: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            _key: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
          })
        )
      })
    ),
    thumbnail_title: PropTypes.string,
    case_thumbnail: PropTypes.shape({
      asset: PropTypes.shape({
        _type: PropTypes.string.isRequired,
        _ref: PropTypes.string.isRequired
      }),
      _type: PropTypes.string.isRequired
    }),
    hero_image: PropTypes.shape({
      asset: PropTypes.shape({
        _ref: PropTypes.string.isRequired
      })
    }).isRequired
  }).isRequired,
  isHeroImage: PropTypes.bool,
  isOnHomepage: PropTypes.bool
}

ImageWithTextOverlay.defaultProps = {
  isHeroImage: false,
  isOnHomepage: false
}

export default ImageWithTextOverlay
