import { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import reducer from './reducer'

const initialState = {
  isLoading: true,
  showForm: false,
  geoInfo: null,
  contactPerson: {
    name: '',
    email: 'novosnegocios@cheil.com'
  },
  about: null,
  footer: null,
  homepage: null,
  jobs: [],
  news: null,
  people: null,
  policy: null,
  terms: null,
  portfolio: [],
  services: null
}

const Context = createContext({
  state: initialState,
  dispatch: () => null
})

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  )
}

export { Context, Provider }

Provider.propTypes = {
  children: PropTypes.node.isRequired
}
