import styled, { keyframes } from 'styled-components'
import { colors } from 'assets/common/colors'
import { StyledCenteredContainer } from 'assets/common/mixin'

const { white, black111 } = colors

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const StyledWorkTitle = styled.h1`
  font-family: 'Montserrat', Arial, Sans-Serif;
  font-size: 4rem; // 64px
  font-style: normal;
  font-weight: 800;
  letter-spacing: -0.10125rem;
  line-height: 120%;
  margin: 0 0 1rem 0;
  padding: 0;

  @media (max-width: 900px) {
    font-size: 2.33332rem;
    letter-spacing: -0.07rem;
  }
`
export const StyledWorkSubtitle = styled.p`
  font-family: 'Montserrat', Arial, Sans-Serif;
  font-style: normal;
  font-size: 2.125rem; // 34px
  font-weight: 800;
  line-height: 120%;
  letter-spacing: -0.06375rem;
  margin: 0 0 1rem 0;
  padding: 0;

  @media (max-width: 900px) {
    font-size: 1.41669rem;
    letter-spacing: -0.0425rem;
  }
`

export const StyledWorkBackground = styled.div`
  background: linear-gradient(${black111} 2650px, ${white} 0);
`

export const StyledWorkWrapper = styled(StyledCenteredContainer)`
  padding-top: 18rem;

  @media (max-width: 900px) {
    padding-top: 13rem;
  }

  img {
    width: 100%;
    height: auto;
  }

  /* @media (max-width: 1024px) {
    padding: 15rem 3.125rem;
  }
  @media (max-width: 900px) {
    padding: 7rem 1.5625rem;
  } */
`

export const StyledWorkTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
    h2 {
      align-self: flex-start;
      margin-bottom: 3rem;
    }
  }
`

export const StyledWorkHeroContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5rem;
`

export const StyledImageContainer = styled.div`
  &:first-child {
    flex-basis: 100%;
    margin-bottom: 5rem;

    @media (max-width: 700px) {
      margin-bottom: 0.5rem;
    }
  }

  &:nth-last-child(-n + 2) {
    flex-grow: 0;
    flex-basis: calc(50% - 0.5rem);
    margin-bottom: 0.5rem;
  }

  &:nth-child(3) {
    margin-top: 13rem;
  }

  @media (max-width: 700px) {
    &:nth-last-child(-n + 2) {
      margin-top: 0;
      flex: 1 0 100%;
    }

    &:nth-child(3) {
      margin-top: 0;
      padding-left: 0;
    }
  }
`

export const StyledWorkArchiveContainer = styled.div`
  margin-top: 12rem;

  @media (max-width: 700px) {
    margin-top: 5rem;
  }
`

export const StyledWorkArchive = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 700px) {
    display: block;
  }
`

export const StyledWorkArchiveImageContainer = styled.div`
  flex-grow: 0;
  flex-basis: calc(50% - 0.5rem);
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  opacity: 0;
  animation: ${fadeIn} 0.4s ease-in-out forwards;
  animation-delay: 0.2s;

  @media (max-width: 500px) {
    flex-grow: unset;
    flex-basis: unset;
  }
`
