import { useContext } from 'react'
import { PortableText } from '@portabletext/react'
import { HelmetSEO, ArticleList } from 'components'
import { Context } from 'store'
import {
  StyledNewsWrapper,
  StyledNewsContent,
  StyledNewsDescription
} from './News.styled'

const helmetCopy = {
  title: 'Novidades | Cheil Brasil',
  canonical: '/novidades',
  description: '',
  keywords: ''
}
const News = () => {
  const {
    state: { news }
  } = useContext(Context)

  const { title, description, articles } = news

  return (
    <>
      <HelmetSEO copy={helmetCopy} />
      <StyledNewsWrapper>
        <StyledNewsContent>
          <h2>{title}</h2>
          <PortableText
            value={description}
            components={{
              block: { normal: StyledNewsDescription }
            }}
          />
        </StyledNewsContent>

        <ArticleList articles={articles} />
      </StyledNewsWrapper>
    </>
  )
}

export default News
