import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const StyledLoader = styled(motion.div)`
  width: 280px;
  aspect-ratio: 1;

  @media (max-width: 1000px) {
    width: 128px;
  }

  @media (max-width: 500px) {
    width: 80px;
  }

  ${({ __isinverted }) => {
    if (__isinverted) {
      return css`
        filter: invert(100%);
      `
    }
    return ''
  }}
`
