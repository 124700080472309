import { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { PortableText } from '@portabletext/react'
import { HashLink } from '@xzar90/react-router-hash-link'
import { Context } from 'store'
import { Button, ContactPersonBlock } from 'components'

import { SubTitle2, SubTitle1, Body1 } from 'assets/common/typography'
import {
  StyledJobsWrapper,
  StyledJobsTitle,
  StyledJobsSubTitleContainer,
  StyledJobsSubTitle,
  StyledListItem,
  StyledJobContactContainer,
  StyledLine
} from './Vacancy.styled'

const Vacancy = () => {
  const {
    state: { jobs }
  } = useContext(Context)
  const { id: vacancyId } = useParams()

  const vacancy = useMemo(
    () => jobs?.find((job) => job._id === vacancyId),
    [jobs, vacancyId]
  )

  if (!vacancy) return null

  const { title, location, contact, department, employment, job_description } =
    vacancy

  return (
    <StyledJobsWrapper>
      <HashLink to="/people/#vacancy">
        <Button textButton="Back to vacancies" isGradient isBefore />
      </HashLink>
      <StyledJobsTitle>{title}</StyledJobsTitle>

      <StyledJobsSubTitleContainer>
        <StyledJobsSubTitle>{department} - </StyledJobsSubTitle>
        <StyledJobsSubTitle> {employment} - </StyledJobsSubTitle>
        <StyledJobsSubTitle> {location}</StyledJobsSubTitle>
      </StyledJobsSubTitleContainer>

      <div>
        <PortableText
          value={job_description}
          components={{
            listItem: {
              bullet: StyledListItem
            },
            block: {
              normal: Body1,
              h2: SubTitle1
            }
          }}
        />
      </div>

      <StyledLine />
      <StyledJobContactContainer>
        <SubTitle2>Got a question?</SubTitle2>
        <ContactPersonBlock
          data={contact}
          note="Enviar mensagem"
          key={contact.key}
        />
      </StyledJobContactContainer>
    </StyledJobsWrapper>
  )
}

export default Vacancy
