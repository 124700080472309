import styled from 'styled-components'
import { colors } from 'assets/common/colors'
import { StyledCenteredContainer } from 'assets/common/mixin'
import { SubTitle2, Body3 } from 'assets/common/typography'
import { motion } from 'framer-motion'

const { white, black111 } = colors

export const StyledServicesButton = styled(motion.div)`
  width: 40px;
  height: 40px;
  z-index: 1;
`

export const StyledServicesNotch = styled.div`
  background-color: white;
  width: 50px;
  height: 50px;
  rotate: 45deg;
  z-index: 5;
  position: absolute;
  margin-top: -5rem;
  display: ${({ index }) => (index === 0 ? 'block' : 'none')};
`

export const StyledServicesButtonNotchContainer = styled.div`
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const StyledServicesCardBackground = styled.div`
  background-color: ${(props) => props.color};
  min-height: 7.56rem;

  /* &:first-child {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${white};
      clip-path: polygon(96% 0, 84% 0, 90% 19%);
      clip-path: polygon(98% 0, 86% 0, 92% 19%);
      clip-path: polygon(94% 0, 86% 0, 90% 21%);
    }
  } */
`

export const StyledServicesItem = styled(StyledCenteredContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  cursor: pointer;

  &:hover {
    svg {
      cursor: pointer;
    }
  }
`

export const StyledServiceName = styled(motion.h2)`
  color: ${black111};
  font-family: 'Montserrat', Arial, Sans-Serif;
  font-size: 2.125rem; // 34px
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: -0.10125rem;
  margin: 1rem 0;
  padding: 0;

  @media (max-width: 900px) {
    font-size: 1.41669rem;
    letter-spacing: -0.0425rem;
  }
`

export const StyledServiceCardContentContainer = styled(motion.div)`
  background-color: ${(props) => props.color};
  color: ${black111};
  padding: 0;
  margin: 0;
  overflow: hidden;

  @media (max-width: 900px) {
    margin-top: 0;
  }
`

export const StyledServiceCardContent = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

export const StyledServiceCardText = styled.div`
  width: 47%;

  @media (max-width: 900px) {
    width: 100%;
  }
`

export const StyledServiceTags = styled(Body3)`
  line-height: 175%;
  letter-spacing: -0.01875rem;
  margin-top: 3rem;
`

export const StyledQuestion = styled(SubTitle2)`
  margin: 5rem 0 2rem;

  @media (max-width: 700px) {
    margin: 3rem 0 0;
  }
`

export const StyledContactsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 2rem;
    margin: 2rem 0 2rem;
  }

  @media (min-width: 1200px) {
    margin: 2rem 0;
    width: 100%;
    gap: 4rem;
  }
`
export const StyledMediaContainer = styled.div`
  width: 624px;
  height: 100%;

  @media (max-width: 900px) {
    margin: 1rem 0 1rem;
    width: unset;
  }
`
export const StyledVideoContainer = styled.video`
  width: 100%;
  height: auto;
  z-index: 2;

  @media (max-width: 900px) {
    margin: 1rem 0 1rem;
  }
`
export const StyledImgContainer = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 900px) {
    margin: 1rem 0 1rem;
    width: 100%;
  }
`
