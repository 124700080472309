import PropTypes from 'prop-types'

import {
  StyledCloseButton,
  StyledContainer,
  StyledIFrame,
  StyledIframeWrapper,
  StyledMask,
  StyledWrapper
} from './VideoModal.sc'

const VideoModal = ({ handleClose, url, title }) => {
  return (
    <StyledMask>
      <StyledWrapper>
        <StyledContainer role="dialog">
          <StyledCloseButton
            type="button"
            onClick={handleClose}
            onKeyPress={handleClose}
            className="close-btn"
            data-omni-type="microsite"
            // data-omni={`${siteCode}:${process.env.REACT_APP_PROJECT_NAME}:video-modal:${title}:close`}
            title="Close"
          />
          <StyledIframeWrapper slot="body">
            <StyledIFrame
              src={url}
              title={title}
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </StyledIframeWrapper>
        </StyledContainer>
      </StyledWrapper>
    </StyledMask>
  )
}

VideoModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default VideoModal
