import { useState, useEffect } from 'react'

export function useIntersection(element, rootMargin) {
  const [isVisible, setState] = useState(false)

  useEffect(() => {
    const copy = element.current

    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting)
      },
      { rootMargin }
    )

    // eslint-disable-next-line no-unused-expressions
    element.current && observer.observe(element.current)

    return () => {
      if (!copy) return
      observer.unobserve(copy)
    }
  }, [element, rootMargin])

  return isVisible
}
