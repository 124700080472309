import {
  CHANGE_IS_LOADING,
  SET_DATA,
  SHOW_CONTACT_FORM,
  SET_CONTACT_PERSON,
  SET_GEO_DATA
} from './actionNames'

const reducer = (state, { type, payload }) => {
  switch (type) {
    case CHANGE_IS_LOADING:
      return {
        ...state,
        isLoading: payload
      }

    case SET_DATA:
      return {
        ...state,
        about: payload?.filter((item) => item._type === 'about')[0],
        footer: payload?.filter((item) => item._type === 'footer')[0],
        homepage: payload?.filter((item) => item._type === 'homepage')[0],
        jobs: payload?.filter((item) => item._type === 'jobs')[0],
        news: payload?.filter((item) => item._type === 'news')[0],
        people: payload?.filter((item) => item._type === 'people')[0],
        policy: payload?.filter((item) => item._type === 'policy')[0],
        terms: payload?.filter((item) => item._type === 'terms')[0],
        portfolio: payload?.filter((item) => item._type === 'portfolio'),
        services: payload?.filter((item) => item._type === 'services')[0]
      }

    case SET_CONTACT_PERSON:
      return {
        ...state,
        contactPerson: payload
      }

    case SET_GEO_DATA:
      return {
        ...state,
        geoInfo: payload
      }

    case SHOW_CONTACT_FORM:
      return {
        ...state,
        showForm: payload
      }

    default:
      return state
  }
}
export default reducer
