import { useState } from 'react'

export const useModal = () => {
  const [isShowing, setShowing] = useState(false)

  const toggle = () => setShowing(!isShowing)

  return {
    isShowing,
    toggle
  }
}
