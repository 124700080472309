import { useEffect, useState } from 'react'

export const useWindowSize = () => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
    isMobile: false
  })

  useEffect(() => {
    const updateSize = () => {
      const { innerWidth, innerHeight } = window

      setSize({
        width: innerWidth,
        height: innerHeight,
        isMobile: innerWidth < 768
      })
    }
    updateSize()

    window.addEventListener('resize', updateSize)

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return size
}
