import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { PortableText } from '@portabletext/react'
import { getFileUrl } from 'sanityClient.js'
import { ContactPersonBlock, VideoBlock } from 'components'
import { ReactComponent as PlusIcon } from 'assets/images/plus_icon.svg'
import { SubTitle1 } from 'assets/common/typography'
// import { ReactComponent as PlayCircle } from 'assets/images/play-circle.svg'
import { colors } from 'assets/common/colors'
import {
  StyledServicesCardBackground,
  StyledServicesItem,
  StyledServiceName,
  StyledServiceCardContentContainer,
  StyledServiceCardContent,
  StyledServiceCardText,
  StyledServiceTags,
  StyledQuestion,
  StyledContactsContainer,
  StyledMediaContainer,
  StyledImgContainer,
  StyledServicesNotch,
  StyledServicesButton,
  StyledServicesButtonNotchContainer
} from './ServiceCard.styled'
import { StyledCenteredContainer } from '../../assets/common/mixin'

const { mint, yellow, magenta, green, purple, pineapple, pink } = colors

export const servicesColors = [
  magenta,
  yellow,
  pink,
  mint,
  green,
  pineapple,
  purple,
  magenta,
  yellow,
  pink,
  mint,
  green,
  pineapple,
  purple
]

const ServiceCard = ({ service, index, isOpen, key }) => {
  const [open, setOpen] = useState(false)
  const [isVideo, setIsVideo] = useState(false)
  const serviceCardRef = useRef()

  const { contacts, description, name, tags, media } = service

  useEffect(() => {
    if (isOpen) {
      setIsVideo(!!media?.asset?._ref.includes('mp4'))
      setTimeout(() => {
        serviceCardRef?.current?.scrollIntoView({ alignToTop: true })
        setOpen(true)
      }, 500)
    }
    // eslint-disable-next-line
  }, [])

  const toggle = () => {
    setOpen(!open)
    if (media && media.asset) {
      setIsVideo(!!media?.asset?._ref.includes('mp4'))
    }
  }

  // const isMatched = SERVICES.find((item) => item.label === service.name)

  const openOffset = 40
  const serviceButtonVariants = {
    open: {
      rotate: -135,
      y: openOffset,
      transition: {
        type: 'spring',
        stiffness: 80,
        damping: 12
      }
    },
    closed: {
      rotate: 0,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 80,
        damping: 12
      }
    }
  }

  const serviceTextVariants = {
    open: {
      y: openOffset,
      transition: {
        type: 'spring',
        stiffness: 80,
        damping: 12
      }
    },
    closed: {
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 80,
        damping: 12
      }
    }
  }

  const serviceContainerVariants = {
    open: {
      height: '100%',
      originY: 0,
      opacity: 1,
      transition: {
        type: 'spring'
      }
    },
    closed: {
      originY: 0,
      opacity: 0,
      height: '0px',
      transition: {
        duration: 0.3
      }
    }
  }

  return (
    <StyledServicesCardBackground
      ref={serviceCardRef}
      color={servicesColors[index]}
    >
      <StyledServicesItem
        id={key}
        key={name}
        open={open}
        onClick={() => toggle()}
        whileHover={{ border: 'solid 1px red' }}
      >
        <StyledServiceName
          variants={serviceTextVariants}
          initial="closed"
          animate={open ? 'open' : 'closed'}
        >
          {name || 'name'}
        </StyledServiceName>
        <StyledServicesButtonNotchContainer>
          <StyledServicesNotch index={index} />
          <StyledServicesButton
            variants={serviceButtonVariants}
            initial="closed"
            animate={open ? 'open' : 'closed'}
          >
            <PlusIcon />
          </StyledServicesButton>
        </StyledServicesButtonNotchContainer>
      </StyledServicesItem>
      <StyledServiceCardContentContainer
        animate={open ? 'open' : 'closed'}
        variants={serviceContainerVariants}
        exit="closed"
        initial="closed"
        color={servicesColors[index]}
        layout
      >
        <StyledCenteredContainer>
          <StyledServiceCardContent>
            <StyledServiceCardText>
              {description && (
                <PortableText
                  value={description}
                  components={{
                    block: {
                      normal: SubTitle1
                    }
                  }}
                />
              )}
              {tags && <StyledServiceTags>{tags}</StyledServiceTags>}

              {window.innerWidth < 900 &&
                media &&
                (isVideo ? (
                  <VideoBlock
                    srcAsset={getFileUrl(media.asset._ref)}
                    title="media"
                  />
                ) : (
                  <StyledImgContainer
                    src={getFileUrl(media.asset._ref)}
                    alt={name}
                  />
                ))}
              {contacts && <StyledQuestion>Alguma dúvida?</StyledQuestion>}

              {/* <StyledQuestion>Got a question?</StyledQuestion> */}
              <StyledContactsContainer>
                {contacts &&
                  contacts.map((contact) => (
                    <ContactPersonBlock
                      key={contact._key}
                      data={contact}
                      isServiceCard
                    />
                  ))}
              </StyledContactsContainer>
            </StyledServiceCardText>

            {window.innerWidth > 900 && (
              <StyledMediaContainer>
                {media &&
                  (isVideo ? (
                    <VideoBlock
                      srcAsset={getFileUrl(media.asset._ref)}
                      title="test"
                    />
                  ) : (
                    <StyledImgContainer
                      src={getFileUrl(media.asset._ref)}
                      alt={name}
                    />
                  ))}
              </StyledMediaContainer>
            )}
          </StyledServiceCardContent>
        </StyledCenteredContainer>
      </StyledServiceCardContentContainer>
    </StyledServicesCardBackground>
  )
}

ServiceCard.propTypes = {
  service: PropTypes.shape({
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        contact_name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        icon: PropTypes.shape({
          asset: PropTypes.shape({
            _type: PropTypes.string.isRequired,
            _ref: PropTypes.string.isRequired
          }),
          _type: PropTypes.string.isRequired
        })
      })
    ),
    description: PropTypes.arrayOf(
      PropTypes.shape({
        _key: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            _key: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired
          })
        )
      })
    ),
    name: PropTypes.string.isRequired,
    tags: PropTypes.string,
    media: PropTypes.shape({
      asset: PropTypes.shape({
        _type: PropTypes.string.isRequired,
        _ref: PropTypes.string.isRequired
      }),
      _type: PropTypes.string.isRequired
    })
  }).isRequired,
  index: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired
}

export default ServiceCard
