import React from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet-async'

const HelmetSEO = ({ copy }) => {
  return (
    <Helmet defaultTitle="Cheil Brasil">
      {copy.title && (
        <title itemProp="name" lang="pt-BR">
          {copy.title}
        </title>
      )}

      {copy.canonical && (
        <link
          rel="canonical"
          href={`https://www.cheil.com.br${copy.canonical}`}
        />
      )}

      {copy.description && (
        <meta name="description" content={copy.description} />
      )}
      {copy.keywords && <meta name="keywords" content={copy.keywords} />}
    </Helmet>
  )
}

HelmetSEO.propTypes = {
  copy: PropTypes.objectOf(PropTypes.string).isRequired
}
export default HelmetSEO
