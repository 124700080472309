import { useContext, useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { PortableText } from '@portabletext/react'
import { Parallax } from 'react-scroll-parallax'
import { getImageUrl } from 'sanityClient.js'
import { Context } from 'store'

import { HelmetSEO, Button, LazyLoaded, AlertModal } from 'components'

import { generatePath } from 'assets/common/helper'
import { Body1, Subtitle4 } from 'assets/common/typography'
import { ReactComponent as RightArrow } from 'assets/images/right_arrow.svg'
import liquidBG from 'assets/images/topo_people.mp4'
import { useWindowSize } from '../../hooks'
import {
  StyledPeopleWrapper,
  StyledContentContainer,
  StyledHeroTitle,
  StyledHeroText,
  StyledValuesTitle,
  StyledValuesDescriptionTitle,
  StyledValuesDescriptionText,
  StyledValuesSubTitle,
  StyledValuesContainer,
  StyledValuesDescription,
  StyledButtonContainer,
  StyledTeamImageContainer,
  StyledVacancyBackground,
  StyledVacancyContainer,
  StyledMobileJob,
  StyledJob,
  StyledJobMobileContentContainer,
  StyledJobMobileSubtitleContainer,
  StyledJobSubtitleContainer,
  StyledJobSubtitle,
  StyledTeamContainer,
  StyledTeamList,
  SecondImage,
  StyledEmployeeCard,
  StyledEmployeeName,
  StyledEmployeeTitle,
  StyledVideoBackground,
  StyledTextOverlay,
  StyledButtonWrapper
} from './People.styled'
import SwiperCarousel from '../../components/SwiperCarousel/SwiperCarousel'

const helmetCopy = {
  title: 'Pessoas | Cheil Brasil',
  canonical: '/pessoas',
  description: '',
  keywords: ''
}

// function shufflerAray(array) {
//   return array.sort(() => Math.random() - 0.5)
// }

const People = () => {
  const [showCount, setShowCount] = useState(16)
  const [showHeight, setShowHeight] = useState(1650)

  const navigate = useNavigate()
  const {
    state: { people, jobs }
  } = useContext(Context)

  const {
    description,
    media,
    photo,
    team_title,
    title: peoplePageTitle,
    vacancies_title,
    value_description,
    value_subtitle,
    value_title,
    team
  } = people

  // const randomizedTeam = useMemo(() => {
  //   const randomizedArray = shuffleArray(team)
  //   return randomizedArray
  // }, [team])

  const videoRef = useRef()

  const handleVacancyClick = (vacancyTitle, location, _id) => {
    navigate(`/people/vacancy/${generatePath(_id, vacancyTitle, location)}`)
  }

  const toggleShowMore = () => {
    setShowCount(showCount + 8)
    setShowHeight(showHeight + 800)
  }

  const innerWidth = useWindowSize().width

  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current
        .play()
        .then(() => {})
        .catch(() => {
          // Video couldn't play, low power play button showing.
        })
    }
  }, [])

  return (
    <>
      <HelmetSEO copy={helmetCopy} />

      <AlertModal />

      <StyledPeopleWrapper>
        <StyledContentContainer>
          <StyledHeroTitle>{peoplePageTitle}</StyledHeroTitle>
          <StyledHeroText>
            <PortableText
              value={description}
              components={{ block: { normal: Body1 } }}
            />
            <StyledButtonContainer>
              <Button
                isGradient
                textButton="vagas em aberto"
                handleClick={() =>
                  window.open(
                    'https://www.linkedin.com/company/cheil-brasil/',
                    '_blank'
                  )
                }
              />
              <Button
                isGradient
                textButton="banco de talentos"
                handleClick={() => window.open('mailto:rh.brasil@cheil.com')}
              />
            </StyledButtonContainer>
          </StyledHeroText>
        </StyledContentContainer>
      </StyledPeopleWrapper>

      <SwiperCarousel media={media} />

      <StyledValuesContainer>
        <StyledValuesSubTitle>{value_subtitle}</StyledValuesSubTitle>
        <StyledContentContainer>
          <StyledValuesTitle>{value_title}</StyledValuesTitle>
          <StyledValuesDescription>
            <PortableText
              value={value_description}
              components={{
                block: {
                  h3: StyledValuesDescriptionTitle,
                  normal: StyledValuesDescriptionText
                }
              }}
            />
            {/* <StyledButtonContainer>
              <Button
                isGradient
                textButton="Sobre"
                handleClick={() => navigate('/sobre')}
              />
            </StyledButtonContainer> */}
          </StyledValuesDescription>
        </StyledContentContainer>
      </StyledValuesContainer>

      <StyledTeamImageContainer>
        <Parallax translateY={[-10, 10]} speed={-5}>
          <img src={getImageUrl(photo).url()} alt="Cheil Brasil" />
        </Parallax>
      </StyledTeamImageContainer>

      {jobs && jobs.length && (
        <StyledVacancyBackground>
          <StyledVacancyContainer id="vacancy">
            <h2>{vacancies_title}</h2>
            {jobs.map(({ _id, title, department, employment, location }) => {
              return innerWidth < 1024 ? (
                <StyledMobileJob
                  key={_id}
                  onClick={() => handleVacancyClick(title, location, _id)}
                >
                  <StyledJobMobileContentContainer>
                    <Subtitle4>{title}</Subtitle4>
                    <StyledJobMobileSubtitleContainer>
                      <StyledJobSubtitle>
                        {department} - {employment} - {location}
                      </StyledJobSubtitle>
                    </StyledJobMobileSubtitleContainer>
                  </StyledJobMobileContentContainer>
                  <RightArrow />
                </StyledMobileJob>
              ) : (
                <StyledJob
                  key={_id}
                  onClick={() => handleVacancyClick(title, location, _id)}
                >
                  <Subtitle4>{title}</Subtitle4>
                  <StyledJobSubtitleContainer>
                    <p style={{ width: '40%' }}>{department}</p>
                    <p style={{ width: '25%' }}>{employment}</p>
                    <p style={{ width: '35%' }}>{location}</p>
                  </StyledJobSubtitleContainer>
                  <RightArrow />
                </StyledJob>
              )
            })}
          </StyledVacancyContainer>
        </StyledVacancyBackground>
      )}

      <StyledTeamContainer>
        <StyledVideoBackground
          ref={videoRef}
          preload="true"
          playsInline
          muted
          loop
        >
          <source src={liquidBG} type="video/mp4" />
        </StyledVideoBackground>
        <StyledTextOverlay>
          <h2>{team_title}</h2>
          <StyledTeamList>
            {team.slice(0, showCount).map((employee) => (
              <StyledEmployeeCard key={employee._key}>
                <LazyLoaded>
                  <img
                    src={getImageUrl(employee.photo).url()}
                    alt={employee.name}
                  />
                  <SecondImage
                    src={getImageUrl(employee.photo2).url()}
                    alt={employee.name}
                  />
                </LazyLoaded>
                <StyledEmployeeName>{employee.name}</StyledEmployeeName>
                <StyledEmployeeTitle>{employee.job_title}</StyledEmployeeTitle>
              </StyledEmployeeCard>
            ))}
          </StyledTeamList>
          {showCount < team.length && (
            <StyledButtonWrapper>
              <Button
                handleClick={toggleShowMore}
                textButton="Ver mais"
                isDownArrow
                isGradient
                whiteBg
              />
            </StyledButtonWrapper>
          )}
        </StyledTextOverlay>
      </StyledTeamContainer>
    </>
  )
}

export default People
