import { useContext } from 'react'
import PropTypes from 'prop-types'
import { PortableText } from '@portabletext/react'
import { Context } from 'store'

import { HelmetSEO, OneAnimation, ServiceCard } from 'components'
import { SubTitle1 } from 'assets/common/typography'
// import { Body1, SubTitle1 } from 'assets/common/typography'
import { colors } from 'assets/common/colors'

import { useLocation } from 'react-router-dom'
import {
  StyledHeroContainer,
  StyledHeroTitle,
  StyledTextContainer,
  StyledServicesDetailsBackground,
  StyledDetailsContainer,
  // StyledTitle,
  StyledContentDescription
} from './Services.styled'
import { SERVICES } from '../../assets/common/constants'

const helmetCopy = {
  title: 'Serviços | Cheil Brasil',
  canonical: '/servicos',
  description: '',
  keywords: ''
}

const { mint, yellow, magenta, green, purple } = colors

export const servicesColors = [magenta, yellow, mint, green, purple]

const ContentDescriptionBlock = {
  block: {
    normal: ({ children }) => (
      <StyledContentDescription>{children}</StyledContentDescription>
    )
  }
}

const Services = () => {
  const {
    state: { services }
  } = useContext(Context)

  const location = useLocation()

  const {
    // content_description,
    // content_title,
    description,
    service_list,
    // subtitle,
    title
  } = services

  return (
    <>
      <HelmetSEO copy={helmetCopy} />
      <StyledHeroContainer>
        <StyledTextContainer>
          <PortableText
            value={title}
            components={{
              block: {
                normal: StyledHeroTitle
              }
            }}
          />
          <PortableText
            value={description}
            components={{
              block: {
                normal: SubTitle1
              }
            }}
          />
        </StyledTextContainer>
        <OneAnimation />
      </StyledHeroContainer>

      <StyledServicesDetailsBackground>
        <StyledDetailsContainer>
          {/* <Body1>{subtitle}</Body1> */}
          {/* <StyledTitle>{content_title}</StyledTitle> */}
          {/* <PortableText
            value={content_description}
            components={{
              block: {
                normal: StyledContentDescription
              }
            }}
          /> */}
        </StyledDetailsContainer>
      </StyledServicesDetailsBackground>

      {service_list.map((service, index) => (
        <ServiceCard
          service={service}
          key={service._key}
          index={index}
          isOpen={
            service._key ===
            SERVICES.find(
              (staticServiceObject) =>
                staticServiceObject.value.toLowerCase() ===
                location.hash.substring(1).toLowerCase()
            )?.key
          }
        />
      ))}
    </>
  )
}

export default Services

ContentDescriptionBlock.propTypes = {
  children: PropTypes.node.isRequired
}
