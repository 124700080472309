import { useContext } from 'react'
import { Context } from 'store'
// import { SERVICES } from 'assets/common/constants'
// import { HashLink } from '@xzar90/react-router-hash-link'

// import { colors } from 'assets/common/colors'
import {
  StyledServicesIntroBackground,
  StyledServicesContainer,
  StyledServicesTitle,
  StyledServicesSubTitle,
  StyledServicesContent,
  StyledServicesDescription
  // StyledServicesNavContainer,
  // StyledServicesNav
} from './ServicesIntro.styled'

// const { mint, yellow, magenta, green, purple } = colors

// const servicesColors = [magenta, yellow, mint, green, purple]

const ServicesIntro = () => {
  const {
    state: { homepage }
  } = useContext(Context)

  const { services_description, services_subtitle, services_title } = homepage

  return (
    <StyledServicesIntroBackground>
      <StyledServicesContainer>
        <StyledServicesSubTitle>{services_subtitle}</StyledServicesSubTitle>
        <StyledServicesContent>
          <StyledServicesTitle>{services_title}</StyledServicesTitle>
          <StyledServicesDescription>
            {services_description}
          </StyledServicesDescription>
          {/* <StyledServicesNavContainer>
            {SERVICES.map((service, index) => (
              <HashLink to={`/servicos#${service.value}`} key={service.value}>
                <StyledServicesNav
                  color={servicesColors[index]}
                  content={service.label}
                >
                  {service.label} →
                  <span color={servicesColors[index]}>{service.label} →</span>
                </StyledServicesNav>
              </HashLink>
            ))}
          </StyledServicesNavContainer> */}
        </StyledServicesContent>
      </StyledServicesContainer>
    </StyledServicesIntroBackground>
  )
}

export default ServicesIntro
