import styled from 'styled-components'
import { colors } from 'assets/common/colors'

const { white } = colors

export const StyledModalWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  @media (max-width: 900px) {
    padding: 0;
  }
`

export const StyledModalContainer = styled.div`
  background-color: ${white};
  width: 90%;
  max-width: 43.75rem;
  padding: 3.125rem;
  color: black;
  position: relative;
  max-height: 95vh;
  overflow: auto;

  img {
    margin-top: 3rem;
    width: 100%;
  }

  @media (max-width: 900px) {
    padding: 1.25rem;
  }
`

export const StyledCloseBtn = styled.div`
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  svg {
    cursor: pointer;
  }
`
