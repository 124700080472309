import styled from 'styled-components'
import { Body2, SubTitle1 } from 'assets/common/typography'
import { StyledCenteredContainer } from 'assets/common/mixin'
import { motion } from 'framer-motion'

export const StyledAboutBackground = styled.div`
  background: url(${({ bgimage }) => bgimage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  min-height: 45rem;

  @media (min-width: 2100px) {
    min-height: 100vh;
  }

  @media (max-width: 900px) {
    min-height: 21.875rem;
  }
`

export const StyledAboutContainer = styled(StyledCenteredContainer)`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const StyledContent = styled.div`
  display: flex;
  margin: 2rem 0;
  gap: 2rem;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 0;
  }
`

export const StyledTopAboutContent = styled(StyledContent)`
  // manual header alignment
  @media (min-width: 1200px) {
    h6 {
      margin-top: 5.5rem;
    }
  }
`

export const StyledBottomAboutContent = styled(StyledContent)`
  // manual header alignment
  /* background: red; */
  /* @media (min-width: 500px) and (max-width: 1200px) {
    h6 {
      max-width: 60vw;
    }
  } */
  @media (min-width: 1200px) {
    h6 {
      margin-top: 0.5rem;
    }
  }
`

export const StyledTeamImageContainer = styled.div`
  overflow: hidden;
  img {
    margin: -2rem 0;
    width: 100%;
    height: auto;
  }

  @media (max-width: 1200px) {
    padding: 0;
    img {
      display: block;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      max-width: 100vw;
      width: 100vw;
    }
  }
`

export const StyledTitle = styled.h1`
  font-family: 'Montserrat', Arial, Sans-Serif;
  font-size: 4rem; // 64px
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: -0.10125rem;
  margin: 0 0 1rem 0;
  padding: 0;
  width: 36%;

  @media (max-width: 500px) {
    width: 100%;
  }

  @media (max-width: 900px) {
    font-size: 2.33332rem;
    letter-spacing: -0.07rem;
  }

  @media (max-width: 1200px) {
    width: 50%;
  }
`

export const StyledTitleFullWidth = styled(StyledTitle)`
  width: 100%;
  margin-top: 5rem;
  text-align: center;

  @media (max-width: 900px) {
    font-size: 2.1875rem;
  }
`

export const StyledIconContainer = styled(motion.div)`
  display: inline-block;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 3rem;

  @media (max-width: 1024px) {
    svg {
      height: 2.5rem;
    }
  }
`

export const StyledPartnerTextContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
`

export const StyledDescription = styled.p`
  font-family: 'Inter', Arial, Sans-Serif;
  font-size: 1.625rem; // 26px
  font-weight: 400;
  letter-spacing: -0.0325rem;
  line-height: 150%;
  margin: 4rem 0;
  padding-top: 5rem;
  width: 72%;

  @media (max-width: 900px) {
    font-size: 1.125rem;
    letter-spacing: -0.0225rem;
  }

  @media (max-width: 1200px) {
    font-size: 1.125rem;
    width: 100%;
    padding-top: 5.5rem;
    margin: 0;
  }
`

export const StyledAwardsDescription = styled(SubTitle1)`
  width: 50%;
  margin-top: 2rem;

  @media (max-width: 900px) {
    font-size: 0.9375rem;
    width: 100%;
  }
`

export const StyledPartnersDescription = styled(StyledDescription)`
  margin: 0;
  padding: 0;
`

export const StyledPartnersListContainer = styled.ul`
  display: flex;
  gap: 5rem;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 7rem;

  @media (max-width: 1024px) {
    flex-direction: row;
    gap: 2rem;
  }
`

export const StyledPartner = styled.li`
  list-style: none;
  flex: 0 0 27%;

  @media (max-width: 900px) {
    flex: 0 0 45%;
  }
`

export const StyledPartnerLogoContainer = styled.div`
  margin-bottom: 1rem;
  height: 2rem;

  img {
    min-height: 1.5rem;
  }
`

export const StyledPartnerText = styled(Body2)`
  color: #777777;

  /* @media (max-width: 900px) {
    font-size: 0.9375rem;
  } */
`

export const StyledMoreInfoLink = styled.div`
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
`

export const StyledAwardsWrapper = styled(StyledCenteredContainer)`
  /* margin-top: -5rem; */
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 900px) {
    margin-top: -5rem;
  }
`

export const StyledAwardsListContainer = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 5rem 0;
`

export const StyledAwardsContainer = styled.div`
  min-height: 8rem;
  max-width: 12rem;
  max-height: 14rem;
  flex: 0 0 13%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;

  img {
    max-width: 5rem;
  }

  @media (max-width: 900px) {
    flex: 1 0 20%;
  }
`

export const StyledAward = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`
