import { useContext, useMemo } from 'react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { HashLink } from '@xzar90/react-router-hash-link'
import { PortableText } from '@portabletext/react'
import { Context } from 'store'
import { getFileUrl } from 'sanityClient.js'
import {
  Button,
  ContactPersonBlock,
  BlockQuote,
  ArticleInfo,
  ArticleList,
  VideoBlock
} from 'components'
import { SubTitle2, SubTitle1, Body1 } from 'assets/common/typography'
import {
  StyledNewsDetailsWrapper,
  StyledHeroMediaContainer,
  StyledNewsTitle,
  StyledNewsContactContainer,
  StyledMoreUpdates,
  StyledLine,
  StyledLongLine
} from './NewsDetails.styled'

const NewsDetails = () => {
  const { key: articleId } = useParams()

  const {
    state: { news }
  } = useContext(Context)

  const { articles } = news

  const article = useMemo(
    () => articles?.find((item) => item._key === articleId),
    [articles, articleId]
  )

  const filteredArticles = articles.filter((item) => item._key !== articleId)

  if (!article) return null

  const { title, published, author, hero_media, info, contact } = article

  return (
    <>
      <StyledNewsDetailsWrapper>
        <HashLink to="/updates">
          <Button textButton="Back" isGradient isBefore />
        </HashLink>
        <StyledNewsTitle>{title}</StyledNewsTitle>
        <Body1>
          {moment(published).format('D MMM YYYY')} {author && `- ${author}`}
        </Body1>
        {hero_media && (
          <StyledHeroMediaContainer>
            <VideoBlock
              srcAsset={getFileUrl(hero_media.asset._ref)}
              title={title}
              isNewsDetails
            />
          </StyledHeroMediaContainer>
        )}
        {info && (
          <PortableText
            value={info}
            components={{
              block: {
                normal: ArticleInfo,
                blockquote: BlockQuote,
                h4: SubTitle1
              }
            }}
          />
        )}
        {contact && <StyledLine />}
        <StyledNewsContactContainer>
          {contact && (
            <>
              <SubTitle2>Got a question?</SubTitle2>
              <ContactPersonBlock data={contact} note="Enviar mensagem" />
            </>
          )}
        </StyledNewsContactContainer>
        <StyledLongLine />
      </StyledNewsDetailsWrapper>
      <StyledMoreUpdates>
        <h4>More updates</h4>
        <ArticleList articles={filteredArticles} />
      </StyledMoreUpdates>
    </>
  )
}

export default NewsDetails
