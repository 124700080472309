import styled from 'styled-components'
import { Body1, Body3 } from 'assets/common/typography'
import { colors } from 'assets/common/colors'
import { StyledCenteredContainer } from 'assets/common/mixin'

const { gray } = colors

export const DynamicSVG = styled.svg`
  width: 100%;
  height: auto;
`

export const StyledFooterBlock = styled.div`
  margin-top: -0.8rem;
`

export const StyledFooterWrapper = styled(StyledCenteredContainer)`
  position: relative;
  margin-top: -1rem;
`

export const StyledFooterContainer = styled.div`
  min-height: 45rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5rem 0 1rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

export const StyledTitleContainer = styled.div`
  flex: 2 0 25%;
  margin-top: -2rem;

  button {
    margin-top: 1.3rem;
  }
`

export const StyledTitle = styled.p`
  font-family: 'Montserrat', Arial, Sans-Serif;
  font-size: 2.125rem; // 34px
  font-style: normal;
  font-weight: 800;
  line-height: 120%;
  letter-spacing: -0.06375rem;
  margin: 0 1rem 0 0;
  padding: 0;
  width: 65%;

  @media (max-width: 900px) {
    font-size: 1.41669rem;
    letter-spacing: -0.0425rem;
  }

  @media (max-width: 900px) {
    font-size: 2.125rem;
    width: 100%;
  }
`

export const StyledDescription = styled(Body1)`
  width: 80%;

  @media (max-width: 900px) {
    width: 100%;
  }
`

export const StyledPeopleContainer = styled.div`
  flex: 1 0 25%;
  margin-top: -1.5rem;

  @media (max-width: 900px) {
    margin: 3rem 0;
  }
`

export const StyledPeople = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const StyledTextEmojiContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

export const StyledContactDetailsContainer = styled.div`
  text-align: right;
  flex: 1 0 15%;
  margin-top: -2rem;

  @media (max-width: 1024px) {
    text-align: left;
    margin-top: 2rem;
  }
`

export const StyledContactDetails = styled.p`
  font-family: 'Inter', Arial, Sans-Serif;
  font-size: 1.625rem; // 26px
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.0325rem;
  margin: 0 1rem 0 0;

  &.contact {
    cursor: pointer;
    margin-top: -1rem;
  }

  @media (max-width: 900px) {
    font-size: 1.125rem;
    letter-spacing: -0.0225rem;
  }

  @media (max-width: 1024px) {
    font-size: 1.375rem;
  }

  @media (min-width: 1024px) and (max-width: 1400px) {
    font-size: 1.3rem;
  }
`

export const StyledAddressesContainer = styled.div`
  display: flex;
  flex-basis: 85%;
  margin-top: 6rem;

  @media (max-width: 1200px) {
    width: 100%;
    gap: 0.3125rem;
    margin-top: 2rem;
  }

  @media (max-width: 900px) {
    display: none;
    visibility: hidden;
  }
`
export const StyledAddresses = styled.div`
  width: 35%;
  color: ${gray};

  @media (max-width: 900px) {
    flex: 45%;
  }

  p {
    margin: 0;
  }
`

export const StyledAddressTitle = styled(Body3)`
  font-weight: 700;
  padding: 0.25rem 0;
`

export const StyledAddressPhoneNumber = styled(Body3)`
  padding: 0.25rem 0;
`

export const StyledLine = styled.hr`
  border: 0.1rem solid #333;
  width: 99.8%;
  position: absolute;
  bottom: 7rem;
  right: 0;

  @media (min-width: 1201px) {
    bottom: 12rem;
  }
  @media (min-width: 901px) and (max-width: 1200px) {
    bottom: 12rem;
  }

  @media (min-width: 501px) and (max-width: 900px) {
    bottom: 21rem;
  }

  @media (max-width: 500px) {
    bottom: 22rem;
  }
`

export const StyledFooter = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  min-height: 6.125rem;
  align-items: center;
  padding-top: 5rem;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const StyleFooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  cursor: pointer;

  .cheil {
    cursor: default;
  }

  @media (max-width: 1200px) {
    align-items: center;
    margin-top: 1rem;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 0.2rem;
    align-items: flex-start;
  }
`

export const StyledSocials = styled.div`
  display: flex;
  gap: 1rem;
  @media (max-width: 500px) {
    img {
      width: 1.875rem;
    }
  }

  @media (max-width: 900px) {
    margin-top: 1rem;
  }
`
