import { useState } from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as PlayCircle } from 'assets/images/play-circle.svg'
import { VideoModal } from 'components'

import {
  StyledVideoBlockContainer,
  StyledPlayCircle,
  StyledVideoContainer
} from './VideoBlock.styled'

const VideoBlock = ({ srcAsset, url, title, isThumbnail }) => {
  const [showPopUp, setShowPopUp] = useState(false)

  let content

  if (srcAsset) {
    if (url) {
      content = (
        <>
          <StyledPlayCircle onClick={() => setShowPopUp(true)}>
            <PlayCircle />
          </StyledPlayCircle>
          {showPopUp === true ? (
            <VideoModal
              handleClose={() => setShowPopUp(false)}
              url={url}
              title={title}
            />
          ) : null}
          <img src={srcAsset} alt="case studies" loading="lazy" />
        </>
      )
      // eslint-disable-next-line react/prop-types
    } else if (srcAsset.includes('mp4')) {
      content = (
        <StyledVideoContainer preload="true" playsInline muted loop controls>
          <source src={srcAsset} type="video/mp4" />
        </StyledVideoContainer>
      )
    } else {
      content = <img src={srcAsset} alt="case studies" loading="lazy" />
    }
  }
  return (
    <StyledVideoBlockContainer
      isThumbnail={isThumbnail}
      // isNewsDetails={isNewsDetails}
    >
      {content}
    </StyledVideoBlockContainer>
  )
}

VideoBlock.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  srcAsset: PropTypes.oneOfType([
    PropTypes.shape({
      _ref: PropTypes.string.isRequired,
      _type: PropTypes.string.isRequired
    }),
    PropTypes.string
  ]).isRequired,
  isThumbnail: PropTypes.bool
  // isNewsDetails: PropTypes.bool
}

VideoBlock.defaultProps = {
  title: '',
  url: '',
  isThumbnail: false
  // isNewsDetails: false
}

export default VideoBlock
